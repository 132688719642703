import type { AppProps } from 'next/app'
import './globals.css'
import '../components/sass/starflow.scss'

export default function App({
  Component,
  pageProps,
}: AppProps) {    
  return <Component {...pageProps} />
}
